@import (reference) "common/less/variables.less";

.popup {
    .content {
        position: absolute;
        font-size: 13px;
        color: @normalTextColor;
        display: flex;
        flex-direction: column;
        z-index: @zIndex[tooltip];
        inline-size: max-content;
        max-width: 500px;
        word-wrap: break-word;
        white-space: pre-line;
        max-height: 100%;
        line-height: normal;
        padding: 4px;
        margin: 5px;
        background-color: @brightBackgroundColor;
        border: 1px solid @borderColor;
        text-transform: none;
        text-align: left;
        border-radius: 4px;
        .subtleBlurryShadow();
        .noFocus();
        .normalFont();
        animation-duration: 0.2s;
        animation-name: fadein;
        opacity: 0;
        animation-fill-mode: forwards;
        -webkit-animation-fill-mode: forwards;
        transition-timing-function: ease;
    
        @keyframes fadein {
            from {
                opacity: 0;
                transform: scale(0.5) translateX(20px);
            }
        
            to {
                opacity: 1;
                transform: scale(1) translateX(10px);
            }
        }
    }
}