@import (reference) 'common/less/variables.less';
@align-properties: flex-start, flex-end, center, stretch, baseline;
@justify-properties: flex-start, flex-end, center, space-between, space-around, space-evenly;
@wrap-properties: nowrap, wrap, wrap-reverse;


@none: @spacing-none;
@xx-small: calc(@spacing/2);
@x-small: @spacing-x-small;
@small: @spacing-small;
@medium: @spacing-medium;
@large: @spacing-large;
@x-large: @spacing-x-large;
@xx-large: @spacing-xx-large;
@xxx-large: @spacing-xxx-large;
@gap-properties: none, xx-small, x-small, small, medium, large, x-large, xx-large, xxx-large;

.flex {
    display: flex;
}

.flex--center {
    justify-content: center;
    align-items: center;
}

.flex--column {
    flex-direction: column;
    width: 100%;
}

.flex--row-reverse {
    flex-direction: row-reverse;
}

.flex--column-reverse {
    flex-direction: column-reverse;
    width: 100%;
}

.flex--remove-width {
    width: unset;
}

.flex--wrap {
    flex-wrap: wrap;
}

.flex--fill-height {
    height: 100%;
}

.flex--fill-width {
    width: 100%;
}

.flex--equal-size {
    > * {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 0;
    }
}

each(@align-properties, {
    .flex--align-@{value} {
        align-items: @value;
    }
});

each(@justify-properties, {
    .flex--justify-@{value} {
        justify-content: @value;
    }
});

each(@wrap-properties, {
    .flex--@{value} {
        flex-wrap: @value;
    }
})

each(@gap-properties, {
    .flex--gap-@{value} {
        gap: @@value
    }
})


