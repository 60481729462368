@import (reference) 'common/less/colorTheme_AMK.less'; // ! Will be removed and replaced with @colors.
@import (reference) 'common/less/variables.less';

/**
 * Mixins used for common ui. Table of contents:
 * - Border radius
 * - Box shadows
 * - Font styles
 * - Layout
 * - Other
 */

 // Scrollbar

.slimScrollbar {
    &::-webkit-scrollbar-track {
        box-sizing: border-box;
        width: 13px;
        height: 13px;
        border: 1px solid @colors[altoGrey];
        border-radius: 5px;
        background-color: @colors[wildSandGrey];
    }
    
    &::-webkit-scrollbar {
        background-color: @colors[altoGrey];
        width: 8px;
        height: 8px;
        border-radius: 5px;
    }
    
    &::-webkit-scrollbar-thumb {
        box-sizing: border-box;
        width: 9px;
        height: 9px;
        border: 1px solid @colors[altoGrey];
        border-radius: 5px;
        background-color: @colors[silver];
    }
 }

// Border radius
.border-radius(@degree) {
    @border-radius-none: 0;
    @border-radius-small: 2px;
    @border-radius-medium: 4px;
    @border-radius-large: 6px;
    @border-radius-x-large: 8px;
    @border-radius-xx-large: 10px;
    @border-radius-oval: 50%;

    @br: "border-radius-@{degree}";

    border-radius: @@br;
}

.remove-border-radius(@side) {
    & when (@side = "right") {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    & when (@side = "top-right") {
        border-top-right-radius: 0;
    }
    & when (@side = "bottom-right") {
        border-bottom-right-radius: 0;
    }
    & when (@side = "left") {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    & when (@side = "top-left") {
        border-top-left-radius: 0;
    }
    & when (@side = "bottom-left") {
        border-bottom-left-radius: 0;
    }
    & when (@side = "all") {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}

// Box Shadow Styles
.applicationShadow() {
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.4);
}

.dialogueShadows() {
    box-shadow: 0 10px 50px -5px rgba(50, 35, 35, 0.35);
}

.separatingShadows() {
    box-shadow: 0 6px 20px -3px rgba(46, 8, 32, 0.25);
}

.sharpShadows() {
    box-shadow: 0 4px 6px 0 rgba(46, 8, 32, 0.25);
}

.subtleShadows() {
    box-shadow: 0 2px 3px 0 rgba(46, 8, 32, 0.25);
}

.subtleBlurryShadow() {
    box-shadow: 0 3px 9px 3px rgba(0, 0, 0, 0.1);
}

// Spacing
.padding(@size) {
    @padding-none: 0;
    @padding-x-small: @spacing-x-small;
    @padding-small: @spacing-small;
    @padding-medium: @spacing-medium;
    @padding-large: @spacing-large;
    @padding-x-large: @spacing-x-large;
    @padding-xx-large: @spacing-xx-large;
    @padding-xxx-large: @spacing-xxx-large;

    @sz: "padding-@{size}";
    padding: @@sz;
}

.margin(@size) {
    @margin-none: 0;
    @margin-x-small: @spacing-x-small;
    @margin-small: @spacing-small;
    @margin-medium: @spacing-medium;
    @margin-large: @spacing-large;
    @margin-x-large: @spacing-x-large;
    @margin-xx-large: @spacing-xx-large;
    @margin-xxx-large: @spacing-xxx-large;

    @sz: "margin-@{size}";
    margin: @@sz;
}

// Font styles
.resetFont {
    font-family: 'Noto Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    text-decoration: none;
    letter-spacing: 0.03em;
}

.normalFont {
    .resetFont();

    font-weight: 400;
    font-size: 13px;
}

.mediumFont {
    font-family: "Noto Sans Med", "Noto Sans", sans-serif;
}

.monoFont {
    font-family: "Noto Mono", monospace;
}

.tinyFont {
    .resetFont();

    font-weight: 300;
    font-size: 10px;
}

.smallFont {
    .resetFont();

    font-size: 11px;
}

.tabFont {
    .resetFont();

    font-weight: 400;
    font-size: 12px;
}

.largeFont {
    .resetFont();

    font-weight: 400;
    font-size: 19px;
}

.extraLargeFont {
    .resetFont();

    font-weight: 600;
    font-size: 29px;
}

.boldFont {
    .resetFont();

    font-weight: bold;
    font-size: 13px;
}

.boldFontStandOut {
    .resetFont();

    font-weight: bold;
    font-size: 14px;
}

.boldFontSmall {
    .resetFont();

    font-weight: bold;
    font-size: 11px;
}

.boldFontLarge {
    .resetFont();

    font-weight: bold;
    font-size: 19px;
}

.headerFont {
    .resetFont();

    font-weight: 400;
    text-transform: uppercase;
    font-size: 13px;
}

// Layout
.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.full-width {
    width: 100%;
    max-width: 100%;
}

// Remove focus from element
.noFocus() {
    &:focus {
        outline: none;
    }
}

// Deselect element
.noSelect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: default;
}

// Banner styles used throughout shift-related components in different parts of Webplatform.
.createBanners() {
    each(@banners, { .banner--@{key} {
        background: repeating-linear-gradient(
            -45deg,
            fade(@value, 60%),
            fade(@value, 60%) 5px,
            @value 5px,
            @value 10px
        );
    }});
}

// Absolut centering

.vertical-align(@position) {
    position: @position;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

// Animations

.keyframes(@name,@rules) {

    @-webkit-keyframes @name {
        @rules();
    }

    @-moz-keyframes @name {
        @rules();
    }

    @-ms-keyframes @name {
        @rules();
    }

    @-o-keyframes @name {
        @rules();
    }

    @keyframes @name {
        @rules();
    }
}

.animation(@arguments){
    animation: @arguments;
    -ms-animation: @arguments;
    -moz-animation: @arguments;
    -webkit-animation: @arguments;
    -o-animation: @arguments;
}

// Not a mixin, but placed here because it belongs to the specific createBanners mixin.
@banners: {
    blue: #2e8ece;
    green: mix(@__theme__lightGreen, @__theme__yellow, 55%);
    mauve: #e0b0ff;
    orange: #ff8114;
    lightorange: #ff9a42;
    brown: #c45100;
    black: #232f35;
    red: #e74c3c;
    yellow: #f1c40f;
    pink: #fa48d3;
}
