@import (reference) 'common/less/variables.less';

.button:focus {
    outline: 0 !important;
}

.button:active {
    outline: 0 !important;
}

.button {
    .border-radius("large");
    border: 1px solid #7cb2c5;
    padding: 0 @spacing-small;
    font-weight: 400;
    text-align: center;
    justify-content: center;
    flex: 0 0 auto;
    flex-direction: row;
    white-space: nowrap;

    .noSelect();

    &-block {
        display: block;
    }

    &-stretch {
        width: 100%;
    }

    &-thin {
        height: 20px !important;
        max-height: 20px !important;
        font-size: 12px !important;
    }

    &-round {
        border-radius: 50px;
    }

    &-uppercase {
        text-transform: uppercase;
    }

    .button_text {
        display: block;
    }

    .buttonContent {
        .flex-center();

        span:not(:first-child) {
            margin-left: @spacing;
        }

        &-iconLeft {
            display: flex;
            align-items: center;
        }

        &-iconRight {
            flex-direction: row-reverse;

            span:not(:first-child) {
                margin-left: 0;
                margin-right: @spacing;
            }
        }

        .iconLeft {
            display: flex;
            align-items: center;
        }

        .iconRight {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
        }

    }

    .icon {
         &.spinnerCircle{
            transform: translate(0, 10%)
        }
    }

    //----------------------------------------------------------------------------
    // Label-type buttons
    // Buttons stripped of all styling
    //----------------------------------------------------------------------------

    &-label {
        border: none;
        border-radius: 0;
        padding: @spacing;
        background-color: transparent;
        font-size: 13px;
        text-align: start;

        // &:hover {
        //     background-color: @colors[black];
        //     color: @colors[white];
        // }
        &.button-disabled {
            border: none;
            background-color: @colors[white];
            color: @colors[silverChalice];
        }
    }

    // &-label:not(.button-disabled):hover {
    //     color: @colors[white];
    // }

    //----------------------------------------------------------------------------
    // Button Types
    //----------------------------------------------------------------------------

    &-secondary {
        background-color: @colors[wildSandGrey];
        color: @colors[bahamaBlue];
        font-size: 13px;

        .icon {
            color: @colors[bahamaBlue];
        }

        &:hover {
            background-color: @colors[lightCyanBlue];
        }

        &:active {
            background-color: @colors[malibuBlue];
        }
    }

    &-primary {
        background-color: @colors[bahamaBlue];
        color: @colors[white];
        border: 1px solid @colors[glacierBlue];

        .icon {
            color: @colors[white];
        }

        &:hover {
            background-color: #3ca0c8;
        }

        &:active {
            background-color: @colors[orientBlue];
        }
    }

    &-danger {
        background-color: @colors[darkRed];
        color: @colors[white];
        border: 1px solid transparent;

        .icon {
            color: @colors[white];
        }

        &:hover {
            background-color: @colors[lightRed];
        }

        &:active {
            background-color: @colors[darkestRed];
        }
    }

    &-disabled {
        .noFocus();
        background-color: #eee;
        border: 1px solid @colors[altoGrey];
        color: @colors[grey];
        // pointer-events: none;

        &:active,
        &:hover {
            background-color: #eee;
        }

        .icon {
            color: @colors[grey] !important;
        }
    }

    &-hidden {
        font-style: italic;
        color: @disabledTextColor;
    }

    &-active {
        background-color: @colors[anakiwaBlue];
        color: @colors[bahamaBlue];

        &:hover {
            background-color: lighten(@colors[anakiwaBlue], 10%);
        }

        &:active {
            background-color: darken(@colors[anakiwaBlue], 10%);
        }
    }

    .controlGroup & {
        &:not(:first-child) {
            // We move the button slightly to the left to merge its border with
            // the border to the left of it. We do not want to set border-left to
            // none, since we want the left border to show when we exchange the
            // element to the left of it with, for example, a spinner, or something
            // else without a border
            margin-left: -1px;
            align-self: stretch;
        }

        margin: 0;
    }

    //----------------------------------------------------------------------------
    // Button Sizes
    //----------------------------------------------------------------------------
    &-small {
        height: 24px;
        font-size: 13px;
    }

    &-medium {
        height: 28px;
        min-width: 60px;
        font-size: 13px;
    }

    &-large {
        height: 36px;
        min-width: 60px;
        font-size: 16px;
    }

    &-x-large {
        height: 48px;
        min-width: 120px;
        font-size: 20px;
    }

    &-iconButton {
        min-width: unset;
    }

    &:not(.button-disabled) {
        cursor: pointer !important;
    }
}
