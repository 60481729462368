@import (reference) 'common/less/variables.less';

.text {
    
    &-regular {
        .resetFont();
        word-wrap: break-word;
        white-space: pre-line;
    }

    &-highlight {
        display: inline;
        background-color: @colors[lighterYellow];
    }

    &-uppercase {
        text-transform: uppercase;
    }

    &-bottom {
        vertical-align: bottom;
    }

    &-center {
        text-align: center;
    }

    &-top {
        vertical-align: top;
    }

    &-bold {
        font-weight: bold;
    }

    &-medium {
        .mediumFont();
    }

    &-italic {
        font-style: italic;
    }

    &-italic {
        font-style: bold;
    }

    &-underline {
        text-decoration: underline;
    }

    &-strikethrough {
        text-decoration: line-through;
    }

    &-monospace {
        .monoFont();
    }

    &-code {
        background-color: @colors[wildSandGrey];
        border: 1px solid @colors[altoGrey];
        border-radius: 4px;
        display: inline;
        font-family: monospace;
        padding: 2px 8px;
    }

    &-copyable {
        display: flex;

        &:hover {
            cursor: pointer;
        }
    }

    &-right {
        text-align: right;
    }

    &-fontSize-small {
        font-size: @fontSize-small;
    }

    &-fontSize-medium {
        font-size: @fontSize-medium;
    }

    &-fontSize-large {
        font-size: @fontSize-large;
    }
    &-fontSize-Xlarge {
        font-size: @fontSize-Xlarge;
    }
    &-fontSize-XXlarge {
        font-size: @fontSize-XXlarge;
    }
}
