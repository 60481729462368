@import (reference) 'common/less/variables.less';

#errorCard {
    max-width: 420px;
    text-align: center;

    .header,
    #reloadText {
        color: @colors[caspecoBlue];
    }

    #reloadImage {
        max-width: 200px;
    }

    //------------spacing------------
    padding: @spacing * 12 @spacing * 4;

    .header {
        margin: @spacing 0;
    }

    .header_level-1 {
        margin-top: @spacing * 8;
    }

    .button {
        margin: @spacing * 2;
    }

    #reloadText {
        margin: @spacing * 4 @spacing * 2 0;
    }
}
