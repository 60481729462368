@import (reference) 'common/less/variables.less';

.icon {
    // z-index: @zIndex[backdrop];
    &--small { font-size: 13px * 0.875; }
    &--smaller { font-size: 13px * 0.75; }
    &--smallest { font-size: 13px * 0.5; }

    &--large { font-size: 13px * 1.125; }
    &--larger { font-size: 13px * 1.25; }
    &--largest { font-size: 13px * 1.5; }

    &--clickable {
        cursor: pointer;

        .placeholder {
            cursor: initial;
        }
    }

    &--hidden {
        visibility: hidden;
    }

    &--placeHolder {
        color: transparent;
    }

    &--disabled {
        color: @disabledTextColor;
        cursor: initial;
    }

    &__badge {
        transform: scale(0.5) translateX(50%) translateY(-50%);
    }

    &--shadow {
        path {
            filter: drop-shadow(2px 2px 1px  @colors[black]);
        }
    }

    &--darkBorder {
        stroke: @colors[black];
        stroke-width: 10px;
    }

    &--lightBorder {
        stroke: @colors[white];
        stroke-width: 10px;
    }
}

//-TEMP FIX- Spin stopped working when upgraded to font awesome V6. Seems to be a issue with the FA react component. Remove when FA fixed this.
.fa-spin {
    animation: spin infinite 2s linear;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
