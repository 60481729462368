
@import (reference) 'common/less/variables.less';

.flashBox {
    &_container {
        display: flex;
        width: fit-content;
        align-items: center;
        flex-direction: row;
        padding: 5px;
        padding-right: 15px;

        &-stretch {
            width: 100%;
        }

        &-filled {
            .border-radius("large");
        }
    }

    &_canHide {
        display: flex;
        align-items: center;
        flex-direction: row;
        padding: 5px;
        padding-right: 15px;
        width: 50px;

        &-filled {
            .border-radius("large");
        }
    }

    &_textWrapper {
        color: @colors[black];
        display: block;
        line-height: 160%;
        text-align: left;
        margin-left: 10px;

        &-invertedColor {
            color: white;
        }
    }

    &_title {
        max-width: inherit;
        margin: 0 0;
        .largeFont();
    }

    &_text {
        max-width: inherit;
        margin: 0 0;
    }

    &_iconWrapper {
        font-size: 48px;
        margin: 5px;
    }

    &-warning {
        & .flashBox_container-filled {
            background-color: @colors[lightestYellow];
            border: 1px solid @colors[lighterYellow];
        }

        & .flashBox_iconWrapper-warning {
            color: @colors[yellow];
            background-color: transparent;
        }
    }

    &-error {
        & .flashBox_container-filled {
            background-color: @colors[lightestRed];
            border: 1px solid @colors[lighterRed];
        }

        & .flashBox_iconWrapper-error {
            color: @colors[red];
            background-color: transparent;
        }
    }

    &-success {
        & .flashBox_container-filled {
            background-color: @colors[lightestGreen];
            border: 1px solid @colors[lightGreen];
        }

        & .flashBox_iconWrapper-success {
            color: @colors[green];
            background-color: transparent;
        }
    }

    &-info,
    &-loading {
        .flashBox_container-filled {
            background-color: @colors[babyBlue];
            border: 1px solid @colors[malibuSkyBlue];
        }
    }

    &-info {
        & .flashBox_iconWrapper-info {
            color: @colors[white];
            background-color: @colors[pictonBlue];
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 38px;
            height: 56px;
            min-width: 56px;
            .border-radius("oval");
        }
    }

    &-loading {
        & .flashBox_iconWrapper-loading {
            background-color: @colors[pictonBlue];
            color: @colors[white];
            padding: 10px;
            border-radius: 60px;
        }
    }

    &-small {
        .flashBox_container-filled {
            padding: 10px;
        }

        .flashBox_textWrapper {
            line-height: 120%;
        }

        .flashBox_title {
            .boldFont();
        }

        .flashBox_text {
            .normalFont();
        }

        .flashBox_iconWrapper {
            font-size: 16px;
        }

        .flashBox_iconWrapper-info {
            font-size: 10px;
            height: 18px;
            min-width: 18px;
        }

        .flashBox_iconWrapper-loading {
            padding: 0;
            border-radius: 20px;

            .spinner > div {
                width: 3px;
            }
        }
    }
}
