@import (reference) "common/less/variables.less";

.spinnerCircle {
    margin: 0 !important;

    &_large {
        .svg-inline--fa {
            width: 19px !important;
        }
    }

    &_medium {
        .svg-inline--fa {
            width: 17px !important;
        }
    }

    &_normal {
        .svg-inline--fa {
            width: 13px !important;
        }
    }

    &_small {
        .svg-inline--fa {
            width: 11px !important;
        }
    }

    &_tiny {
        .svg-inline--fa {
            width: 10px !important;
        }
    }

    &_setRight {
        .svg-inline--fa {
            width: 50px !important;
            height: 20px !important;
            margin: 0 auto;
            text-align: center;
            font-size: 10px;
        }
    }

    .spinner_ {
        &rect2 {
            -webkit-animation-delay: -1.1s;
            animation-delay: -1.1s;
        }

        &rect3 {
            -webkit-animation-delay: -1s;
            animation-delay: -1s;
        }

        &rect4 {
            -webkit-animation-delay: -0.9s;
            animation-delay: -0.9s;
        }

        &rect5 {
            -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
        }
    }

    &_inverted {
        .svg-inline--fa {
            color: white;
        }
    }
}

/* .spinner > div {
    background-color: white;
    height: 100%;
    width: 6px;
    margin: 0 1px;
    display: inline-block;

    -webkit-animation: stretchdelay 1.2s infinite ease-in-out;
    animation: stretchdelay 1.2s infinite ease-in-out;
}

.spinner.spinner_dark > div {
    background-color: @spinnerBgColor;
}

@-webkit-keyframes stretchdelay {
    0%, 40%, 100% {
        -webkit-transform: scaleY(0.4)
    }
    20% {
        -webkit-transform: scaleY(1.0)
    }
}

@keyframes stretchdelay {
    0%, 40%, 100% {
        transform: scaleY(0.4);
        -webkit-transform: scaleY(0.4);
    }
    20% {
        transform: scaleY(1.0);
        -webkit-transform: scaleY(1.0);
    }
}
 */
