@import (reference) 'common/less/variables.less';

@borderRadiusDegrees: none, small, medium, large, x-large, xx-large, oval;
@borderRadiusSizes: 0, 2px, 4px, 6px, 8px, 10px, 50%;

.br(@list, @i: 1) when (@i <= length(@list)) {
    @size: extract(@borderRadiusSizes, @i);
    @name: extract(@borderRadiusDegrees, @i);

    .br-@{name} {
        border-radius: @size;
    }
    .br(@list, @i + 1);
}

.br(@borderRadiusDegrees);
