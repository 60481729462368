@import (reference) "common/less/variables.less";

@darkFilter: rgba(32, 32, 32, 65%);

.tooltip-container {
    position: absolute;
    font-size: 13px;
    color: @normalTextColor;
    display: flex;
    flex-direction: column;
    z-index: @zIndex[tooltip];
    inline-size: max-content;
    max-width: 500px;
    word-wrap: break-word;
    white-space: pre-line;
    max-height: 100%;
    line-height: normal;
    padding: 4px;
    margin: 5px;
    background-color: @brightBackgroundColor;
    border: 1px solid @borderColor;
    text-transform: none;
    text-align: left;
    border-radius: 4px;
    .subtleBlurryShadow();
    .noFocus();
    .normalFont();
    animation-duration: 0.2s;
    animation-name: fadein;
    opacity: 0;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    transition-timing-function: ease;
    overflow: hidden;

    @keyframes fadein {
        from {
            opacity: 0;
            transform: scale(0.5) translateX(20px);
        }

        to {
            opacity: 1;
            transform: scale(1) translateX(10px);
        }
    }

    .mobile &_root {
        display: flex;
        justify-content: center;
        z-index: @zIndex[backdrop];
        flex-direction: column;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: @darkFilter;
        padding: @spacing * 6;
        cursor: pointer;
    }

    .mobile &_content {
        max-height: 60%;
        overflow: auto;
        display: block;
        z-index: @zIndex[tooltip];
        margin: 0;
        padding: @spacing * 4;
        border: 1px solid @borderColor;
        box-shadow: 0 0 40px @darkFilter;
        border-radius: 4px;
        background-color: @colors[white];
        font-size: 14px;
        
        div:last-child {
            padding-bottom: 0; //last div in content has no padding or margin.
            margin-bottom: 0;
        }
    }
}