@import (reference) 'common/less/variables.less';

@shadow-properties: "application", "dialogue", "separating", "sharp", "subtle", "subtleBlurry";

.card {
    padding: @spacing * 3;
    overflow: hidden;
    background-color: white;
    font-family: "Noto Sans", sans-serif;

    hr {
        height: 1px;
        margin-bottom: @spacing-medium;
        background-color: @colors[altoGrey];
        border: none;
    }

    &.border {
        border: 1px solid @colors[coldGrey];
    }

    &.centered {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex-wrap: nowrap;

        .cardTitle {
            text-align: center;
            justify-content: center;
            width: 100%;
            // Handles IE bug with text overflowing parent container.
        }
    }

    &.flexstart {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        flex-wrap: nowrap;
    }

    &.fillParentHeight {
        height: 100%;
    }

    &.clickable {
        cursor: pointer;
    }

    &.borderRadius {
        .remove-border-radius("left");
        .remove-border-radius("right");
    }

    &.noPadding {
        padding: 0;
    }
    each(@shadow-properties, {
        & when (@value = "application") {
            &.shadow-application {
                .applicationShadow();
            }
        }
        & when (@value = "dialogue") {
            &.shadow-dialogue {
                .dialogueShadows();
            }
        }
        & when (@value = "separating") {
            &.shadow-separating {
                .separatingShadows();
            }
        }
        & when (@value = "sharp") {
            &.shadow-sharp {
                .sharpShadows();
            }
        }
        & when (@value = "subtle") {
            &.shadow-subtle {
                .subtleShadows();
            }
        }
        & when (@value = "subtleBlurry") {
            &.shadow-subtleBlurry {
                .subtleBlurryShadow();
            }
        }
    });
}

.cardContent_spinnerWrapper {
    justify-content: center;
}

.cardTitle {
    display: flex;
    overflow: hidden;
    font-size: 13px;
    font-weight: 600;
    align-content: center;
    align-items: center;
    color: @subHeaderTextColor;
    margin-bottom: 5px;

    &.secondary {
        height: 16px;
        color: #6E6E6E;
        font-family: "Noto Sans";
        font-size: 16px;
        letter-spacing: 0;
        line-height: 22px;
        margin-bottom: @spacing * 2;
    }
}

//overwrite of listView_row because default element set max-width: 400px cuts a button in half
.card .tableView__row {
    & td {
        max-width: 500px !important;
    }
}

.mobile & {
    .card {
        background-color: white;
        border: 1px solid @borderColor;
        .border-radius("large");
    }

    .cardTitle {
        overflow: hidden;
        color: @subHeaderTextColor;
        padding: 5px 0;
    }
}

.tablet {
    .cardTitle {
        font-size: 22px;

        &.large {
            font-size: 19px;
        }
    }
}
