@import 'common/less/spacing.less';
@import 'common/less/flex.less';
@import 'common/less/borderRadius.less';

.sr-only {
    position: absolute;
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0;
    border: 0;
    height: 1px;
    width: 1px;
    overflow: hidden;
}
