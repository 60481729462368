
@import (reference) 'common/less/variables.less';

.spinner {
    margin: 0 auto;
    width: 50px;
    height: 20px;
    text-align: center;
    font-size: 10px;

    .spinner_ {
        &rect2 {
            -webkit-animation-delay: -1.1s;
            animation-delay: -1.1s;
        }

        &rect3 {
            -webkit-animation-delay: -1s;
            animation-delay: -1s;
        }

        &rect4 {
            -webkit-animation-delay: -0.9s;
            animation-delay: -0.9s;
        }

        &rect5 {
            -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
        }
    }
}

.spinner > div {
    background-color: white;
    height: 100%;
    width: 6px;
    margin: 0 1px;
    display: inline-block;
    -webkit-animation: stretchdelay 1.2s infinite ease-in-out;
    animation: stretchdelay 1.2s infinite ease-in-out;
}

.spinner.spinner_dark > div {
    background-color: @spinnerBgColor;
}

@-webkit-keyframes stretchdelay {
    0%,
    40%,
    100% {
        -webkit-transform: scaleY(0.4);
    }

    20% {
        -webkit-transform: scaleY(1);
    }
}

@keyframes stretchdelay {
    0%,
    40%,
    100% {
        transform: scaleY(0.4);
        -webkit-transform: scaleY(0.4);
    }

    20% {
        transform: scaleY(1);
        -webkit-transform: scaleY(1);
    }
}
