@import (reference) 'common/less/variables.less';

.header {
    .resetFont();
    font-family: "Noto Sans", sans-serif;
    font-weight: 600;
    margin: 0 !important;

    &-align {
        &-left {
            text-align: left;
        }

        &-right {
            text-align: right;
        }

        &-center {
            text-align: center;
        }
    }

    &_level {
        &-1 {
            font-size: 2.25rem;
            line-height: 2.5rem;
        }

        &-2 {
            font-size: 1.75rem;
            line-height: 2rem;
        }

        &-3 {
            font-size: 1.5rem;
            line-height: 1.75rem;
        }

        &-4 {
            font-size: 1.25rem;
            line-height: 1.5rem;
        }

        &-5 {
            font-size: 1rem;
            line-height: 1.25rem;
            letter-spacing: -0.005rem;
        }
    }
}
