@import (reference) 'common/less/variables.less';

@spaces: none, x-small, small, medium, large, x-large, xx-large, xxx-large;
@spacing: @spacing-none, @spacing-x-small, @spacing-small, @spacing-medium, @spacing-large, @spacing-x-large, @spacing-xx-large, @spacing-xxx-large;

.mx-spacing(@list, @i: 1) when (@i <= length(@list)) {
    @margin: extract(@spacing, @i);
    @name: extract(@spaces, @i);

    .mx-@{name} {
        margin-left: @margin;
        margin-right: @margin;
    }
    .mx-spacing(@list, @i + 1);
}

.my-spacing(@list, @i: 1) when (@i <= length(@list)) {
    @margin: extract(@spacing, @i);
    @name: extract(@spaces, @i);

    .my-@{name} {
        margin-top: @margin;
        margin-bottom: @margin;
    }
    .my-spacing(@list, @i + 1);
}

.mr-spacing(@list, @i: 1) when (@i <= length(@list)) {
    @margin: extract(@spacing, @i);
    @name: extract(@spaces, @i);

    .mr-@{name} {
        margin-right: @margin;
    }
    .mr-spacing(@list, @i + 1);
}

.ml-spacing(@list, @i: 1) when (@i <= length(@list)) {
    @margin: extract(@spacing, @i);
    @name: extract(@spaces, @i);

    .ml-@{name} {
        margin-left: @margin;
    }
    .ml-spacing(@list, @i + 1);
}

.mt-spacing(@list, @i: 1) when (@i <= length(@list)) {
    @margin: extract(@spacing, @i);
    @name: extract(@spaces, @i);

    .mt-@{name} {
        margin-top: @margin;
    }
    .mt-spacing(@list, @i + 1);
}

.mb-spacing(@list, @i: 1) when (@i <= length(@list)) {
    @margin: extract(@spacing, @i);
    @name: extract(@spaces, @i);

    .mb-@{name} {
        margin-bottom: @margin;
    }
    .mb-spacing(@list, @i + 1);
}

.mx-spacing(@spaces);
.my-spacing(@spaces);
.mr-spacing(@spaces);
.ml-spacing(@spaces);
.mt-spacing(@spaces);
.mb-spacing(@spaces);
